import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {BaseComponent} from '../../pages/_base/base/base.component';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationStart,
  RouterEvent,
  RouterStateSnapshot
} from '@angular/router';
import {StorageName} from '../../_models/components/storage-name';
import {RoutingTypes} from '../../_models/components/routing-types';
import {LoggedUserService} from '../../_services/public/local/logged-user.service';
import {AuthService} from '../../_services/_base/auth.service';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';
import { environment } from '../../../environments/environment';
import { SubscriptionTypes } from 'src/app/_models/components/subscription-types';
import { CompanyProfileInterface } from 'src/app/_models/api/whitelabel/whitelabel-interface';
import { SubscriptionsInterface } from 'src/app/_models/api/subscriptions/subscriptions-interface';
import {CompanyService} from '../../_services/companies/company.service';
import {Response} from '../../_models/api/response';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit {
  year: number = new Date().getFullYear();
  @Input() menuItemsGen: any[];
  @Input() menuItemsApp: any[];
  @Output() toggleSidenav = new EventEmitter<void>();
  routingTypes = RoutingTypes;
  authenticated: boolean = false;
  username = '';
  publicView = false;
  // clientType: string = !!localStorage.getItem('clientType') ? localStorage.getItem('clientType') : 'business';
  clientType: string = 'business';
  lang: string = localStorage.getItem('lang') || "en";
  selectedRegion: Region;
  selectedCurrency: Currency;
  selectedLang: Language;
  currency: string = !!localStorage.getItem('currency') ? localStorage.getItem('currency') : 'eur';
  region: string = !!localStorage.getItem('region') ? localStorage.getItem('region') : 'all';
  partnership = localStorage.getItem(StorageName.partnership);
  reteclima = this.partnership === PartnershipNames.reteclima;
  adnoc = this.partnership === PartnershipNames.adnoc;
  aldar = this.partnership === PartnershipNames.aldar;
  tgi = this.partnership === PartnershipNames.tgi;
  whitelabel = this.partnership === PartnershipNames.whitelabel;
  whitelabelData: CompanyProfileInterface;
  windowWidth:any = window.innerWidth

  subscriptionPlugin = localStorage.getItem(StorageName.subscription);
  partnershipSidebarOptions = {
    [PartnershipNames.reteclima]: [
      'home',
      'dashboard',
      'projects',
      'settings',
      'logout'
    ],
    [PartnershipNames.adnoc]: [
      'home',
      'dashboard',
      'projects',
      'settings',
      'logout'
    ],
    [PartnershipNames.aldar]: [
      'home',
      'dashboard',
      'project',
      'settings',
      'logout'
    ],
    [PartnershipNames.tgi]: [
      'home',
      'dashboard',
      'tgi-project',
      'settings',
      'logout'
    ]
  };
  productionSidebarOptions = [
    'home',
    'dashboard',
    'energy',
    'projects',
    'sustainability',
    'settings',
    'logout'
  ];
  productionSidebarOptionsSustainabilityEnabled = [
    'home',
    'dashboard',
    'energy',
    'projects',
    'sustainability_enabled',
    'settings',
    'logout'
  ];
  devSidebarOptions = [
    'home',
    'dashboard',
    'energy',
    'projects',
    'sustainability',
    'whitelabel',
    'settings',
    'logout'
  ];
  devSidebarOptionsSustainabilityEnabled = [
    'home',
    'dashboard',
    'energy',
    'projects',
    'sustainability_enabled',
    'whitelabel',
    'settings',
    'logout'
  ];

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private companyService: CompanyService
  ) {
    super();
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart){

      }
    });
  }

  ngOnInit(): void {
    this.getLocale();
    this.getUsername();
    this.publicView  = !this.router?.url?.startsWith('/admin');
    if (this.whitelabel) {
      this.whitelabelData = (JSON.parse(localStorage.getItem(StorageName.profile))) as CompanyProfileInterface;
      let customSocial = false;
      this.whitelabelData.socials?.forEach((social) => {
        if (social.url && social.enabled) {
          customSocial = true;
        }
      });
      this.whitelabelData.customSocial = customSocial;
    }
    if (this.publicView) {
      this.menuItemsGen?.forEach((item) => {
        item.children?.forEach((child) => {
          if (child.type && child.type != this.clientType) {
            child.visible = false;
          }
        });
      });
    }
    else{
      if (Object.keys(this.partnershipSidebarOptions).includes(this.partnership)) {
        this.menuItemsApp = this.menuItemsApp.filter(item => this.partnershipSidebarOptions[this.partnership].includes(item.displayName));
      } else if (environment.production){
        this.menuItemsApp = this.menuItemsApp.filter(item => this.productionSidebarOptions.includes(item.displayName));
      } else{
        this.getCompanySubscriptions().then();
      }
    }
  }

  getLocale(): void {
    this.selectedLang = Language[this.lang];
    this.selectedRegion = Region[this.region];
    this.selectedCurrency = Currency[this.currency];
  }

  getUsername(): void {
    this.loggedUserService.logged.subscribe(() => {
      this.username = this.loggedUserService.getUsername();
    })
    this.username = this.loggedUserService.getUsername();
    if (this.auth.isAuthenticated()) {
      this.authenticated = true;
      if (!this.username) {
        this.username = this.translate.instant('landingPublic.menu.access');
      }
    }
  }

  openLocaleModal(): void {
    this.openLocale(
      this.translate.instant('locale.title'),
      'large',
      this.translate.instant('locale.language'),
      '',
      true,
      (result) => this.setLocale(result),
      'Yes'
    );
  }

  setLocale(data): void {
    localStorage.setItem('lang', data.lang);
    this.goToLocalizedUrl(this.router.url, data.lang);
    localStorage.setItem('region', data.region);
    localStorage.setItem('currency', data.currency);
    location.reload();
  }

  setSidebar(): void {
    if (environment.production)
      this.menuItemsApp = this.menuItemsApp.filter(item => this.productionSidebarOptionsSustainabilityEnabled.includes(item.displayName));
    else
      this.menuItemsApp = this.menuItemsApp.filter(item => this.devSidebarOptionsSustainabilityEnabled.includes(item.displayName));
  }

  getCompanySubscriptions = async () => {
    const companyId = localStorage.getItem(StorageName.companyId);
    try {
      if (this.subscriptionPlugin == SubscriptionTypes.climatePioneer){
        this.setSidebar();
        return;
      } else {
        const response: Response<SubscriptionsInterface[]> = await this.companyService.getCompanySubscriptionsByGroupSlug(companyId,SubscriptionTypes.climatePioneer,true);
        if (response?.success) {
          if (response.data.length>0){
            localStorage.setItem(StorageName.subscription, response.data[0].subscriptionType?.group?.slug);
            localStorage.setItem(StorageName.subscriptionId, response.data[0].id);
            this.subscriptionPlugin = response.data[0].subscriptionType?.group?.slug;
            this.setSidebar();
            return;
          }
        } else {
          this.showErrorResponse(response);
        }
      }
    } catch (e) {
      this.showErrorResponse(e);
    }
    this.menuItemsApp = this.menuItemsApp.filter(item => this.devSidebarOptions.includes(item.displayName));
  };
}


enum Language {
  "en" = 'en',
  "it" = 'it',
  "jp" = 'jp'
}

enum Region {
  "all" = 'Global',
  "eu" = 'Europe',
  "mena" = "Middle East",
  "na" = "North America",
  "sa" = "South America",
  "asia" = "Asia Pacific"
}

enum Currency {
  "($) USD" = 'usd',
  "(€) EUR" = 'eur',
  usd = "($) USD",
  eur = "(€) EUR"
}
