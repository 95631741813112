import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppInjectorService } from '../../../_services/_base/app-injector.service';
import Swal from 'sweetalert2';
import { SweetAlertInterface } from '../../../_models/components/sweet-alert';
import { Response } from '../../../_models/api/response';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../_services/shared.service';
import { StorageName } from '../../../_models/components/storage-name';
import { ModalComponent } from '../../../components/modal/modal.component';
import { LocaleComponent } from '../../../components/locale/locale.component';
import { ModalType } from '../../../_models/components/modal-types';
import { RoleTypes } from '../../../_models/components/role-types';
import { ErrorCodes } from '../../../_models/components/error-codes';
import { AlertTypes } from '../../../_models/components/alert-types';
import { UserTypes } from '../../../_models/components/user-types';
import { environment } from '../../../../environments/environment';
import { ShareFriendsComponent } from '../../../components/share-friends/share-friends.component';
import { OrderSummaryService } from '../../../_services/public/local/order-summary.service';
import { NavItem } from '../../../_models/components/nav-item';
import { Meta } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LoggedUserService } from '../../../_services/public/local/logged-user.service';
import { Observable } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { ProductInterface } from '../../../_models/product-interface';
import { RoutingTypes } from '../../../_models/components/routing-types';
import { SinglePaymentPacksInterface } from '../../../_models/api/public/single-payment-packs-interface';
import { UnitTypes } from '../../../_models/components/unit-types';
import { ClientType } from '../../welcome/welcome.component';
import { ProjectTypesInterface } from 'src/app/_models/api/projects/project-types-interface';
import { ProjectTypes } from 'src/app/_models/components/project-types';
import { ProjectsInterface } from 'src/app/_models/api/projects/projects-interface';
import { MatDialog } from '@angular/material/dialog';

// import * as Sentry from '@sentry/browser';
const sidebarProfile = require('src/assets/json/sidebar-profile.json');
const sidebarApp = require('src/assets/json/sidebar-pages.json');
const sidebarSubscription = require('src/assets/json/sidebar-subscription.json');
const individualMenuPublic = require('src/assets/json/individual-sidebar-public.json');
const menuPublic = require('src/assets/json/sidebar-public.json');

@Component({
  selector: 'app-base',
  template: '',
})

export class BaseComponent implements OnInit {
  protected router: Router;
  public translate: TranslateService;
  protected dialog: MatDialog;
  protected toastr: ToastrService;
  protected sharedService: SharedService;
  protected meta: Meta;
  protected loggedUserService: LoggedUserService;
  protected gtmService: GoogleTagManagerService;

  sidebarProfile: NavItem[];
  sidebarApp: NavItem[];
  sidebarSubscription: NavItem[];
  menuPublic: NavItem[];

  visible: boolean = false;
  count: number = 0;

  showSettings = environment.showSettings;
  addImpactEnabled = environment.enableImpact;
  shareButtonEnabled = environment.enableShare;

  cdn = environment.awsBaseUrl;

  constructor() {
    this.loggedUserService = AppInjectorService.injector.get(LoggedUserService);
    this.dialog = AppInjectorService.injector.get(MatDialog);
    this.meta = AppInjectorService.injector.get(Meta);
    this.router = AppInjectorService.injector.get(Router);
    this.translate = AppInjectorService.injector.get(TranslateService);
    this.toastr = AppInjectorService.injector.get(ToastrService);
    this.sharedService = AppInjectorService.injector.get(SharedService);
    this.gtmService = AppInjectorService.injector.get(GoogleTagManagerService);
  }

  ngOnInit(): void {
    /* const isIndividual = localStorage.getItem('clientType') === '1';
    this.menuPublic = isIndividual ? individualMenuPublic : menuPublic; */
    const isIndividual = false;
    this.menuPublic = menuPublic;
    this.sidebarProfile = sidebarProfile;
    this.sidebarApp = sidebarApp;
    this.sidebarSubscription = sidebarSubscription;
  }

  logError = (e: any) => {
    console.error(e);
  }

  setLoginData(data:any) {
    const cart = localStorage.getItem(StorageName.cart);
    const termly = localStorage.getItem('TERMLY_API_CACHE');
    const leader = localStorage.getItem(StorageName.businessEmployees);
    const pioneer = localStorage.getItem(StorageName.businessPioneer);
    const lang = localStorage.getItem('lang');
    const pluginCheck = localStorage.getItem(StorageName.pluginCheck);
    const subscription = localStorage.getItem(StorageName.subscription);
    const subscriptionId = localStorage.getItem(StorageName.subscriptionId);
    const whitelabelWelcome = localStorage.getItem(StorageName.whitelabelWelcome);
    localStorage.clear();
    localStorage.setItem('lang', lang);
    localStorage.setItem('TERMLY_API_CACHE', termly);
    localStorage.setItem(StorageName.cart, cart);
    localStorage.setItem(StorageName.userData, JSON.stringify({firstName: data.firstname, lastName: data.lastname, email: data.email}));
    localStorage.setItem(StorageName.token, data.token);
    localStorage.setItem(StorageName.id, data.id);
    localStorage.setItem(StorageName.username, data.firstname);
    if (whitelabelWelcome) localStorage.setItem(StorageName.whitelabelWelcome, whitelabelWelcome);
    let clientType = '1';
    if (data?.type == UserTypes.business) {
      clientType = '2';
    }
    localStorage.setItem(StorageName.clientType, clientType);
    if (leader) {
      localStorage.setItem(StorageName.businessEmployees, leader);
    }
    if (pioneer) {
      localStorage.setItem(StorageName.businessPioneer, pioneer);
    }
    if (pluginCheck) {
      localStorage.setItem(StorageName.pluginCheck, pluginCheck);
    }
    if (data.addresses) {
      localStorage.setItem(StorageName.country, data.addresses.country_id);
    }
    if (data.pilio) {
      localStorage.setItem(StorageName.pilio, data.pilio.toString());
    }
    if (data.pilioLastAccess) {
      localStorage.setItem(StorageName.pilioLastAccess, data.pilioLastAccess.toString());
    }
    if (data.company) {
      localStorage.setItem(StorageName.username, data.company.name);
      localStorage.setItem(StorageName.companyName, data.company.name);
      localStorage.setItem(StorageName.userCompanyRole, data.role.slug);
      localStorage.setItem(StorageName.companyId, data.company.id);
    }
    if (data.subscription) {
      localStorage.setItem(StorageName.subscription, data.subscription.subscriptionType?.group?.slug);
      localStorage.setItem(StorageName.subscriptionId, data.subscription.id);
    }
    // Send GTM event
    this.identify('login', 'GFP');
    this.loggedUserService.logged.emit();
  }

  goToLocalizedUrl = async (route: string, lang: string = 'en') => {

    const URL_MAP = [
      {
        en: RoutingTypes.ENERGY,
        it: RoutingTypes.ENERGY_IT
      },
      {
        en: RoutingTypes.CARBON_CREDITS,
        it: RoutingTypes.CARBON_CREDITS_IT
      },
      {
        en: RoutingTypes.ESG,
        it: RoutingTypes.ESG_IT
      },
      {
        en: RoutingTypes.SOLUTIONS,
        it: RoutingTypes.SOLUTIONS_IT
      },
      {
        en: RoutingTypes.WHITELABEL,
        it: RoutingTypes.WHITELABEL_IT,
      },
      {
        en: RoutingTypes.SUSTAINABILITY,
        it: RoutingTypes.SUSTAINABILITY_IT,
      }
    ];

    const EnglishRoutes = URL_MAP.map(item => item.en);
    const ItalianRoutes = URL_MAP.map(item => item.it);

    if (
      !EnglishRoutes.some(englishRoute => route.includes(englishRoute)) &&
      !ItalianRoutes.some(italianRoute => route.includes(italianRoute))) {
        throw new Error(`Invalid localized route. Route "${route}"`);
      }

    if (lang !== 'en' && lang !== 'it') {
      throw new Error(`Invalid language for localized route. Language "${lang}"`);
    }

    const routes = URL_MAP.find(item => route.includes(item.en) || route.includes(item.it));

    this.router.navigate([routes[lang]], {
      queryParamsHandling: 'preserve'
    });
  }

  logout = async (route?: string, queryParams?: string) => {
    const termly = localStorage.getItem('TERMLY_API_CACHE');
    const lang = localStorage.getItem('lang');
    const whitelabelWelcome = localStorage.getItem(StorageName.whitelabelWelcome);
    const whitelabelSaved = localStorage.getItem(StorageName.whitelabelSaved);
    /* localStorage.removeItem(StorageName.token);
    localStorage.removeItem(StorageName.companyId);
    localStorage.removeItem(StorageName.userCompanyRole);
    localStorage.removeItem(StorageName.id);
    localStorage.removeItem(StorageName.subscriptionId);
    localStorage.removeItem(StorageName.username);
    localStorage.removeItem(StorageName.cart);
    localStorage.removeItem(StorageName.pilio);
    localStorage.removeItem(StorageName.pilioLastAccess);
    localStorage.removeItem(StorageName.userData);
    localStorage.removeItem(StorageName.companyName);
    localStorage.removeItem(StorageName.partnership); */
    localStorage.clear();
    localStorage.setItem('lang', lang);
    localStorage.setItem('TERMLY_API_CACHE', termly);
    localStorage.setItem(StorageName.clientType, ClientType.business.toString());
    if (whitelabelWelcome) {
      localStorage.setItem(StorageName.whitelabelWelcome, whitelabelWelcome);
    }
    if (whitelabelSaved) {
      localStorage.setItem(StorageName.whitelabelSaved, whitelabelSaved);
    }
    this.loggedUserService.logout.emit();
    if (route){
      if (queryParams != '') await this.router.navigate([route], { queryParams: {partner: queryParams}});
      else await this.router.navigate([route]);
    }
  }

  showError = (message: string) => {
    const toast = this.toastr.error(
      message,
      this.translate.instant('warning'),
      {
        closeButton: true,
        toastClass: 'alert alert-with-icon',
        positionClass: 'toast-top-right'
      }
    );
    toast.onHidden = this.onClose()
  }

  showErrorResponse = (response: Response<any>, from = 'top', align = 'right') => {
    if (!environment.production) {
      console.log(response)
    }
    // Sentry.captureException(response);
    let unexpected = false;
    let message = '';
    let title = '';
    let toastClass = 'alert alert-with-icon';
    switch (response?.code) {
      case ErrorCodes.INCORRECT_PASSWORD:
        message = this.translate.instant('authentication.incorrectPassword');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.USER_ALREADY_EXIST:
        message = this.translate.instant('authentication.userAlreadyExist');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.USER_NOT_FOUND:
        message = this.translate.instant('authentication.userNotFound');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.GIFT_CARD_ALREADY_REDEEMED:
        message = this.translate.instant('authentication.giftCardAlreadyRedeemed');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.NOT_AUTHORIZED:
        message = this.translate.instant('authentication.notAuthorized');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.USER_CREATED:
        message = this.translate.instant('authentication.created');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.DELETED_ACCOUNT:
        message = this.translate.instant('authentication.deletedAccount');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.PROJECT_NOT_FOUND:
        message = this.translate.instant('authentication.projectNotFound');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.SUBSCRIPTION_NOT_FOUND:
        message = this.translate.instant('authentication.subscriptionNotFound');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.ALREADY_EMPLOYEE:
        message = this.translate.instant('authentication.alreadyEmployee');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.success:
        message = this.translate.instant('authentication.success');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.INVALID_DATA:
        message = this.translate.instant('authentication.invalidData');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.INVALID_TOKEN:
        message = this.translate.instant('authentication.invalidToken');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.NO_TOKEN_PROVIDE:
        message = this.translate.instant('authentication.noTokenProvide');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.RESOURCE_ALREADY_EXISTS:
        message = this.translate.instant('authentication.resourceAlreadyExists');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.PERMISSION_ALREADY_EXISTS:
        message = this.translate.instant('authentication.permissionAlreadyExists');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.COMPANY_BILLING_ADDRESS_NOT_CREATED:
        message = this.translate.instant('authentication.companyBillingAddressNotCreated');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.COMPANY_ADDRESS_NOT_CREATED:
        message = this.translate.instant('authentication.companyAddressNotCreated');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.COMPANY_NOT_CREATED:
        message = this.translate.instant('authentication.companyNotCreated');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.SUBSCRIPTION_NOT_CREATED:
        message = this.translate.instant('authentication.subscriptionNotCreated');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.USER_NOT_HAVE_SUBSCRIPTION:
        message = this.translate.instant('errorCodeMessages.userNotHaveSubscription');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.PROMO_CODE_ALREADY_USED:
        message = this.translate.instant('errorCodeMessages.promoCodeAlreadyUsed');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.PROMO_CODE_NOT_FOUND:
        message = this.translate.instant('errorCodeMessages.promoCodeNotFound');
        title = this.translate.instant('warning');
        break;
      case ErrorCodes.NEWSLETTER_USER_ALREADY_EXISTS:
        message = this.translate.instant('errorCodeMessages.newsletterUserAlreadyExists');
        title = this.translate.instant('warning');
        break;
      default:
        message = this.translate.instant('unexpectedError');
        title = this.translate.instant('warning');
        unexpected = true;
        this.count += 1;
        toastClass += ' unexpected'
        break;
    }
    if (response.message) {
      message = response.message;
    }
    if (!this.visible) {
      const toast = this.toastr.error(
        message,
        title,
        {
          closeButton: true,
          toastClass: toastClass,
          positionClass: 'toast-' + from + '-' + align,
        }
      );
      this.visible = unexpected;
      toast.onHidden = this.onClose();
    }
  }

  onClose(): Observable<void> {
    this.visible = false;
    return new Observable<void>();
  }

  showAlert = (
    {
      text,
      title,
      type,
      icon,
      timer,
      cancelButtonText,
      confirmButtonText,
      willClose
    }: SweetAlertInterface): Promise<any> => {

    if (type === AlertTypes.basic) {
      return Swal.fire({
        heightAuto: false,
        title,
        customClass: {
          confirmButton: 'mat-raised-button mat-button-base mat-success'
        },
      });
    } else if (type === AlertTypes.titleAndText) {
      return Swal.fire({
        heightAuto: false,
        title,
        text,
        customClass: {
          confirmButton: 'mat-button'
        }
      });
    } else if (type === AlertTypes.successMessage) {
      return Swal.fire({
        heightAuto: false,
        title,
        text,
        customClass: {
          confirmButton: 'mat-raised-button mat-button-base mat-success'
        },
        icon,
        willClose: () => {
          if (willClose){
            var F=new Function (willClose);
            return(F());
          }
        }
      });
    } else if (type === AlertTypes.warningConfirmation) {
      return Swal.fire({
        heightAuto: false,
        title,
        text,
        showCancelButton: true,
        customClass: {
          confirmButton: 'mat-raised-button mat-button-base mat-success',
          cancelButton: 'mat-raised-button mat-button-base mat-warning',
        },
        confirmButtonText,
        icon
      });
    } else if (type === AlertTypes.warningCancel) {
      return Swal.fire({
        heightAuto: false,
        title,
        text,
        showCancelButton: true,
        customClass: {
          confirmButton: 'mat-raised-button mat-button-base mat-success mr-1',
          cancelButton: 'mat-raised-button mat-button-base mat-warning',

        },
        confirmButtonText,
        cancelButtonText,
        icon
      });
    } else if (type === AlertTypes.autoClose) {
      return Swal.fire({
        heightAuto: false,
        title,
        text,
        showConfirmButton: false,
        timer
      });
    }
  }

  openModal = (
    modalTitle: string,
    type: string = typeof ModalType,
    // types: 'small' | 'medium' | 'large',
    primaryText: string,
    secondaryText?: string,
    seeAction?: boolean,
    action?: () => void,
    buttonLabel?: string,
    buttonCancelLabel?: string
  ) => {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: {
        title: modalTitle,
        size: type,
        firstText: primaryText,
        secondText: secondaryText,
        showAction: seeAction,
        buttonText: buttonLabel,
        buttonCancelLabel: buttonCancelLabel
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        action();
      }
    });
  }

  openLocale = (
    modalTitle: string,
    type: string = typeof ModalType,
    // types: 'small' | 'medium' | 'large',
    primaryText: string,
    secondaryText?: string,
    seeAction?: boolean,
    action?: (data) => void,
    buttonLabel?: string
  ) => {
    const dialogRef = this.dialog.open(LocaleComponent, {
      data: {
        title: modalTitle,
        size: type,
        firstText: primaryText,
        secondText: secondaryText,
        showAction: seeAction,
        buttonText: buttonLabel
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        action(result.data);
      }
    });
  }

  showSuccess = (message: string, title: string) => {
    this.toastr.success(
      message,
      title,
      {
        closeButton: true,
        toastClass: 'alert alert-with-icon',
        positionClass: 'toast-top-right'
      }
    );
  }

  canSeeCompanyData(): boolean {
    const companyRoles = [RoleTypes.COMPANY_OWNER, RoleTypes.COMPANY_ADMIN];
    return companyRoles.includes(localStorage.getItem(StorageName.userCompanyRole));
  }

  roundNumber(val: number, approximation = 2): number {
    const app = 10 ** approximation;
    return Math.round(val * app) / app;
  }

  humanize(val: number, currency?: string): string {
    let locale = 'en-gb';
    if (localStorage.getItem('lang') === 'it') {
      locale = 'it-it';
    }
    if (currency) {
      return Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(val);
    }
    let maximumFractionDigits = 2;
    let intVal = Math.round(val);
    if (intVal.toString().length > 6)
      return Intl.NumberFormat(locale).format(intVal);
    else
      return Intl.NumberFormat(locale, {maximumFractionDigits: maximumFractionDigits}).format(val);
  }

  stringify(obj: any): any {
    let cache = [];
    let str = JSON.stringify(obj, function(key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          return;
        }
        cache.push(value);
      }
      return value;
    });
    cache = null;
    return str;
  }

  showCustomAlert(route: ActivatedRoute, title: string, type: string): void {
    if (route.snapshot.queryParams?.invited) {
      this.showAlert({
        title: this.translate.instant(title),
        text: this.translate.instant(type),
        type: AlertTypes.titleAndText
      }).then();
    }
    this.router.navigate([], {
      queryParamsHandling: 'preserve',
    }).then();
  }

  validateEmail(email): any {
    const chars = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return chars.test(String(email).toLowerCase());
  }

  retrieveStyleBackgroundPublic(img: string): any {
    if (!img) {
      return {
        'background': '#ccc'
      }
    }
    return {
      background: `url("${img}") no-repeat`,
      'background-size': 'cover',
      'background-position': '50% 50%',
      'background-opacity': '0.8'
    };
  }

  scrollToId(id: string): void {
    if (id) {
      const element = document.getElementById(id);
      element.style["scroll-margin-top"]="70px";
      element.style["overflow"]="clip";
      element.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }

  getPreservationValue(data: number): any {
    if (data < 1){
      return this.translate.instant('orderSummary.m2Unit', {value: this.roundNumber(data * 10000)});
    }
    else{
      if (data == 1) {
        return this.translate.instant('orderSummary.hectareUnit', {value: data});
      }
      return this.translate.instant('orderSummary.hectaresUnit', {value: this.roundNumber(data, 2)});
    }
  }

  goTo(url: string): void {
    this.router.navigate([url]).then();
  }

  routeToCalendly(): void {
    this.identify('book_demo', 'Calendly');
    window.open('https://calendly.com/green-future-project', '_blank')
  }

  bookADemo(type: string = 'website', open: boolean = true) {
    // this.identify('Book_demo_' + type, 'Hubspot');
    // let meetingLink = 'https://meetings-eu1.hubspot.com/elena-cecconi/' + type;
    // if (localStorage.getItem('lang') === 'it') {
    //   meetingLink = 'https://meetings-eu1.hubspot.com/elena-cecconi/'+ type +'-ita';
    // }
    // if (open) {
    //   window.open(
    //     meetingLink,
    //     '_blank'
    //   );
    // } else {
    //   return meetingLink;
    // }
    this.identify('Book_demo_' + type, 'Hubspot');
    let meetingLink = 'https://page.greenfutureproject.com/request-demo?utm_source=website_ENG&utm_medium=' + type + '&utm_campaign=demo';
    if (localStorage.getItem('lang') === 'it') {
      meetingLink = 'https://page.greenfutureproject.com/richiedi-demo?utm_source=website_ITA&utm_medium=' + type + '&utm_campaign=demo';
    }
    if (open) {
      window.open(
        meetingLink,
        '_blank'
      );
    } else {
      return meetingLink;
    }
  }

  asProduct(
    id: string, name: string, currency: string, price: number, quantity: number,
    variant: string, category: string, subcategory?: string, listId?: string, listName?: string
  ): ProductInterface {
    const product: ProductInterface = {
      item_id: id,
      item_name: name,
      item_brand: 'Green Future Project',
      index: 0,
      item_category: category,
      item_category2: subcategory,
      item_variant: variant,
      currency: currency,
      price: price,
      quantity: quantity,
      item_list_id: listId,
      item_list_name: listName
    }
    return product;
  }

  identify(event: string, method: string): void {
    const gtmTag = {
      event: event,
      method: method
    };
    if (!environment.production) {
      console.log(gtmTag);
    }
    this.gtmService.pushTag(gtmTag);
  }

  track(
    event: string, products: ProductInterface[]
  ): void {
    this.gtmService.pushTag({ecommerce: null});
    const gtmTag = {
      event: event,
      ecommerce : {
        items: products
      }
    };
    if (!environment.production) {
      console.log(gtmTag)
    }
    this.gtmService.pushTag(gtmTag);
  }

  trackPurchase(
    id: string, value: number, tax: number, shipping: number, currency: string,
    products: ProductInterface[]
  ): void {
    let productIds = [];
    products?.forEach((product) => {
      productIds.push(product.item_id)
    });
    this.gtmService.pushTag({ecommerce: null});
    const gtmTag = {
      event: 'purchase',
      ecommerce : {
        transaction_id: id,
        value: value,
        tax: tax,
        shipping: shipping,
        currency: currency,
        items: products
      },
      facebook: {
        content_type: 'product',
        content_ids: productIds
      }
    };
    if (!environment.production) {
      console.log(gtmTag)
    }
    this.gtmService.pushTag(gtmTag);
  }

  calculateCo2Trees = (quantity: number, interval: number, co2KgPerUnit: number, date: Date): number => {
    /* Calclulate the CO2 absorbed from trees planted over time */
    const date1 = new Date(date);
    const date2 = new Date();
    let co2 = 0;
    const currentDate = date2.getDate();
    const rolloverDate = date1.getDate();
    let diffMonth = (
      (date2.getFullYear() - date1.getFullYear()) * 12 +
      date2.getMonth() - date1.getMonth()
    );
    if (currentDate < rolloverDate && diffMonth > 0) {
      diffMonth -= 1;
    }
    for (let i = 0; i <= diffMonth + 1; i++) {
      if (interval > 0) {
        co2 += interval * co2KgPerUnit * i;
      } else {
        if (diffMonth != 0 || i != 0) {
          co2 += quantity * co2KgPerUnit;
        }
      }
    }
    return co2;
  };

  getUnitPrice(pack: SinglePaymentPacksInterface): any {
    let unitPrice: number;
    let lowestItem: any;
    let lowestPrice = 1000;
    let lowestQuantity = 1;
    pack.singlePayments?.forEach((item) => {
      const co2 = this.roundNumber(((item.quantity * pack.unit.co2KgPerUnit) / 1000), 2);
      if (item.quantity == 1 || co2 == 1) {
        lowestQuantity = item.quantity;
        lowestPrice = item.price;
        unitPrice = item.price;
        if (pack.unit.slug == UnitTypes.HECTARE) {
          if (item.quantity == 1) {
           unitPrice /= 10000
          } else if (co2 == 1) {
           unitPrice *= pack.unit.co2KgPerUnit / 1000;
          }
        }
      }
      if (item.price > 0 && item.price < lowestPrice) {
        lowestPrice = item.price;
        lowestQuantity = item.quantity;
        lowestItem = item;
      }
    });
    return {
      unit: pack.unit,
      quantity: pack.unit.slug == UnitTypes.KWH ? lowestQuantity : 1,
      price: unitPrice ? unitPrice :
        this.roundNumber(lowestPrice / lowestQuantity, 2),
    }
  }

  getUnitString(quantity: number, projectType: any, unit: any, pack: SinglePaymentPacksInterface): any {
    if(unit){
      const unitName = unit.unit.slug === UnitTypes.HECTARE ?
        'm2' : quantity === 1 ?
        unit.unit.name :
        unit.unit.name_plural;
      let action = '';
      switch (projectType.slug) {
        case ProjectTypes.premium:
          action = (unit.unit.slug === UnitTypes.HECTARE) ? this.translate.instant('admin.protected') : (quantity === 1) ? this.translate.instant('admin.1planted') : this.translate.instant('admin.planted');
          break;
        case ProjectTypes.restoration:
          action = quantity === 1 ? this.translate.instant('admin.1planted') : this.translate.instant('admin.planted');
          break;
        case ProjectTypes.marine_restoration:
          action = quantity === 1 ? this.translate.instant('admin.planted_seedling') : this.translate.instant('admin.planted_seedlings');
          break;
        case ProjectTypes.preservation:
          if (pack.unit?.slug == UnitTypes.TONNE) {
            action = this.translate.instant('admin.co2Offset');
          } else {
            action = this.translate.instant('admin.protected');
          }
          break;
        case ProjectTypes.renewable_energy:
          action = this.translate.instant('admin.produced');
          break;
      }
      return this.translate.instant('admin.'+unitName) + ` ${action}`;
    }
  }

  getImpactString(projectType: ProjectTypesInterface, project: ProjectsInterface, unit: any, pack: SinglePaymentPacksInterface): any {
    let type = '';
    let impactStr = '';
    switch (projectType.slug) {
      case ProjectTypes.premium:
        type = 'eachTree';
        break;
      case ProjectTypes.restoration:
        if (project.slug == 'ferru') type = 'eachTree';
        else type = 'eachMangrove';
        break;
      case ProjectTypes.marine_restoration:
        type = 'eachSeedling';
        break;
      case ProjectTypes.preservation:
        type = 'eachM2';
        break;
      case ProjectTypes.renewable_energy:
        type = 'eachKwh'
        break;
    }
    let co2 = unit.quantity * pack.unit.co2KgPerUnit;
    if (
      projectType.slug == ProjectTypes.restoration || project.slug.startsWith('posi')
    ) {
      co2 *= 12;
    } else if (projectType.slug == ProjectTypes.preservation) {
      co2 /= 10000;
    }
    impactStr = this.translate.instant(
      'sustainability.dashboard.' + type,
      {
        project: project.name,
        co2: this.humanize(this.roundNumber(co2, 2))
      }
    );
    if (projectType.slug == ProjectTypes.restoration || project.slug.startsWith('posi') ) {
      impactStr += this.translate.instant('sustainability.dashboard.perYear');
    }
    return impactStr;
  }

}
