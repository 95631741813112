import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../../pages/_base/base/base.component';
import {RoutingTypes} from '../../_models/components/routing-types';
import {MatSidenav} from '@angular/material/sidenav';
import {MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger} from '@angular/material/legacy-menu';
import {NavigationEnd} from '@angular/router';
import {StorageName} from '../../_models/components/storage-name';
import {OrderSummaryService} from '../../_services/public/local/order-summary.service';
import {CartTypes} from '../../_models/components/cart-types';
import {LoggedUserService} from '../../_services/public/local/logged-user.service';
import {AuthService} from '../../_services/_base/auth.service';
// import { environment } from 'src/environments/environment';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';
import { ExpertDialogComponent } from '../expert-dialog/expert-dialog.component';

@Component({
  selector: 'app-public-navbar',
  templateUrl: './public-navbar.component.html',
  styleUrls: ['./public-navbar.component.scss']
})
export class PublicNavbarComponent extends BaseComponent implements OnInit {
  route: string;
  scrollY = 0;
  @Input() menuItemsApp: any[];
  @Output() toggleSidenav = new EventEmitter<void>();
  @ViewChild('menuTrigger') matMenuTrigger: MatMenuTrigger;
  username = '';
  authenticated: boolean = false;
  nElements = 0;
  // clientType: string = !!localStorage.getItem('clientType') ? localStorage.getItem('clientType') : 'business';
  clientType: string = 'business';
  lang: string = localStorage.getItem('lang') || "en";
  selectedLang: Language;
  region: string = !!localStorage.getItem('region') ? localStorage.getItem('region') : 'all';
  selectedRegion: Region;
  currency: string = !!localStorage.getItem('currency') ? localStorage.getItem('currency') : 'eur';
  selectedCurrency: Currency;
  windowWidth:any = window.innerWidth
  opened:boolean = false
  reteclima: boolean = location.href.toLowerCase().includes('reteclima');
  aldar: boolean = false;
  utm_medium: string = 'HP_topnav';
  projects = ['/projects','/about/restoration','/about/preservation','/about/renewable_energy','/about/marine_restoration']
  others = ['/about','/nft','/gift','/termsConditions','/privacyPolicy','/cookiePolicy','/monthlyClimateSubscription']

  constructor(
    // private loggedUserService: LoggedUserService,
    private cartService: OrderSummaryService,
    private auth: AuthService
  ) {
    super();
  }

  // isProduction = environment.production;

  ngOnInit(): void {
    this.route = this.router.url;
    if (this.route == "/") localStorage.removeItem(StorageName.partnership);
    this.aldar = (this.route.includes('aldar')||localStorage.getItem(StorageName.partnership) == PartnershipNames.aldar);
    this.getLocale();
    this.getUsername();
    this.openCart();
    this.closeCart();
    this.setBadge();
    this.setActive();
    this.router.events.subscribe(res => {
      //console.log(res);
      this.setActive();
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
  }

  isOpened():void{
    this.opened = !this.opened
  }

  setLang(lang): void {
    let currentLang = localStorage.getItem('lang');
    if (currentLang != lang) {
      localStorage.setItem('lang', lang);
      this.changeRoutePath(lang)
      location.reload();
    }
  }

  getLocale(): void {
    this.selectedLang = Language[this.lang];
    this.selectedRegion = Region[this.region];
    this.selectedCurrency = Currency[this.currency];
  }

  openLocaleModal(): void {
    this.openLocale(
      this.translate.instant('locale.title'),
      'large',
      this.translate.instant('locale.language'),
      '',
      true,
      (result) => this.setLocale(result),
      'Yes'
    );
  }

  openExpertModal(): void {
    const dialogRef = this.dialog.open(ExpertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log('The dialog was closed');
      }
    });
  }

  setLocale(data): void {
    localStorage.setItem('lang', data.lang);
    localStorage.setItem('region', data.region);
    localStorage.setItem('currency', data.currency);
    this.changeRoutePath(data.lang)
    location.reload();
  }

  changeRoutePath(lang: string): void {
    this.goToLocalizedUrl(this.router.url, lang);
    if (this.router.url == '/gestione_impronta_carbonio' && lang === 'en') {
      window.history.pushState({},null,'/carbon_footprint_management')
    } else if (this.router.url == '/carbon_footprint_management' && lang === 'it') {
      window.history.pushState({},null,'/gestione_impronta_carbonio')
    } else if (this.router.url == '/consulenza-sostenibilita-ambientale' && lang === 'en') {
      window.history.pushState({},null,'/environmental-sustainability-advisory')
    }  else if (this.router.url == '/environmental-sustainability-advisory' && lang === 'it') {
      window.history.pushState({},null,'/consulenza-sostenibilita-ambientale')
    }else if (this.router.url == '/carbon_offset_API' && lang === 'en') {
      window.history.pushState({},null,'/sustainability_plugin')
    }  else if (this.router.url == '/sustainability_plugin' && lang === 'it') {
      window.history.pushState({},null,'/carbon_offset_API')
    } else if (this.router.url == '/crediti-di-carbonio-token-biodiversita' && lang === 'en'){
      window.history.pushState({}, null, '/carbon-offset-biodiversity-token')
    } else if(this.router.url == '/carbon-offset-biodiversity-token' && lang === 'it'){
      window.history.pushState({}, null, '/crediti-di-carbonio-token-biodiversita')
    }
  }

  setActive(): void {
    //reset active values first
    this.menuItemsApp = this.menuItemsApp.map(item => {
      return {
        ...item, 
        active: false
      }})
    this.menuItemsApp.forEach((item) => {
      switch (item.displayName) {
        case 'home':
          if (this.router.url === '/') {
            item.active = true;
          }
          break;
        case 'projects':
          if (this.router.url === '/projects') {
            item.active = true;
          }
          break;
        default:
          if (this.router.url === item.route) {
            item.active = true;
          }
          break;
      }
      item.children?.forEach((child) => {
        if (this.router.url === child.route || this.router.url === child.route_it) {
          item.active = true;
          child.active = true;
        }
        if(child.displayName == 'blog' && this.lang == 'it'){
          child.route = child.route_it;
        } else if(child.displayName == 'esg' && this.lang == 'jp'){
          child.visible = false;
        }
      })
    });
  }

  store(clientType: ClientTypes): void {
    localStorage.setItem('clientType', clientType);
    if (this.router.url != '/') {
      this.router.navigate([RoutingTypes.HOME_PUBLIC]);
      setTimeout(() => {
        location.reload();
      }, 500)
    } else {
      location.reload()
    }
  }

  getUsername(): void {
    this.loggedUserService.logged.subscribe(() => {
      this.username = this.loggedUserService.getUsername();
    })
    this.username = this.loggedUserService.getUsername();
    if (this.auth.isAuthenticated()) {
      this.authenticated = true;
      if (!this.username) {
        this.username = this.translate.instant('landingPublic.menu.access');
      }
    }
  }

  setBadge(): void {
    this.nElements = this.cartService.getItemsByType(CartTypes.GIFT)?.length;
  }

  openCart(): void {
    this.cartService.dataUpdated.subscribe(data => {
      this.setBadge();
      if (data) {
        this.matMenuTrigger.openMenu();
      }
    });
  }

  closeCart(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.matMenuTrigger) {
        this.matMenuTrigger.closeMenu();
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  handlerScroll(event): void {
    this.scrollY = event.currentTarget.scrollY;
  }

  login(): void {
    this.router.navigate([RoutingTypes.LOGIN]).then();
  }

  admin(): void {
    this.router.navigate([RoutingTypes.ADMIN]).then();

  }

  setState(event): void {
    this.matMenuTrigger.closeMenu();
  }

  createBookADemoLink(){
    let path = window.location.pathname;
    const pathMap = {
      '/': "HP_topnav",
      '/carbon_neutral_solutions': 'Solutions_topnav',
      '/carbon_footprint_management': 'Carbon_topnav',
      '/carbon-offset-biodiversity-token': 'Offset_topnav',
      '/climatePositiveSubscription': 'Positive_topnav',
      '/sustainability_plugin': 'Plugin_topnav',
      '/whitelabel_environmental_communication': 'WL_topnav',
      '/environmental-sustainability-advisory' : 'Advisory_topnav',
      '/sustainability-reporting': 'Reporting_topnav',
      '/climate-solutions-partners' : 'CSPartners_topnav',
      '/strategic-partners': 'StrategicPartners_topnav',
      ...Object.fromEntries(
        this.projects.map(project=> [project, 'Projects_topnav'])
      ),
      '/decarbonisation_tech_alliance': 'DTA_topnav',
      '/faq': 'Contact_topnav',
      '/soluzioni_carbon_neutral': 'Solutions_topnav',
      '/gestione_impronta_carbonio': 'Carbon_topnav',
      '/crediti-di-carbonio-token-biodiversita': 'Offset_topnav',
      '/carbon_offset_API': 'Plugin_topnav',
      '/comunicazione_sostenibilita_whitelabel': 'WL_topnav',
      '/consulenza-sostenibilita-ambientale': 'Advisory_topnav',
      '/piano-bilancio-sostenibilita': 'Reporting_topnav',
      '/partner-strategici': 'StrategicPartners_topnav',
      ...Object.fromEntries(
        this.others.map(other=> [other, 'Other_topnav'])
      )

  }
    this.utm_medium = path.toString().includes('projects') ? 'Projects_topnav' : pathMap[path]
    this.bookADemo(this.utm_medium);
  }

}

enum Language {
  "en" = 'en',
  "it" = 'it',
  "jp" = 'jp'
}

enum Region {
  "all" = 'Global',
  "eu" = 'Europe',
  "mena" = "Middle East",
  "na" = "North America",
  "sa" = "South America",
  "asia" = "Asia Pacific"
}

enum Currency {
  "($) USD" = 'usd',
  "(€) EUR" = 'eur',
  usd = "($) USD",
  eur = "(€) EUR"
}
