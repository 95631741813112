<div class="container" id="achievementsData" (window:resize)="onResize()" [ngClass]="{'px-4': windowWidth < 600, 'd-flex justify-content-center': align=='center'}">
  <div class="gfp-widget p-7" [ngClass]="{'vertical': forApi, 'reteclima': reteclima, 'adnoc': adnoc, 'aldar': aldar, 'tgi': tgi, 'whitelabel': whitelabel}">
    <div class="main d-flex flex-column">
      <div class="header">
        <div class="title-2 bold" *ngIf="!company && projectTypeName">
          {{'landingPublicImpacts.our' | translate: {value:projectTypeName} }}
        </div>
        <div class="title-2 bold" *ngIf="!company && !projectTypeName">
          {{'landingPublicImpacts.total' | translate }}
        </div>
        <div class="title-2 bold company-name" *ngIf="company">
          {{'landingPublicImpacts.climateImpact' | translate: {value:company.name} }}
        </div>
        <div class="logo" *ngIf="(windowWidth >= 992 && !forApi && !isVertical && !reteclima)">
          <span class="px-3">Powered by</span>
          <svg-icon src="assets/images/svg/logoTitle.svg" [svgStyle]="{'width.px':46, 'height.px':40}"></svg-icon>
        </div>
        <div class="logo d-flex gap-3" *ngIf="reteclima">
          <svg-icon src="assets/images/icons/reteclima_logo.svg"></svg-icon>
          <svg-icon id="gfp-reteclima-logo" src="assets/images/svg/logoTitle.svg"></svg-icon>
        </div>
      </div>
      <div class="sub" [ngClass]="((windowWidth < 992 || isVertical) && !showStats) ? 'mobile-size' : ''">
        <div class="stats w-100" id="stats-id" *ngIf="showStats">
          <div class="content text-center justify-content-start align-items-end" id="content-top">
            <div class="item" *ngIf="projectType != 'marine_restoration'" >
              <div class="icon">
                <img src="/assets/images/public/home/co2_offset.svg" />
              </div>
              <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
              <div class="impact co2" >
                <div class="value">
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.co2 > 0">{{countersPublicInterface?.co2Str}}</span>
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.co2 == 0">0</span>
                </div>
                <div class="small-body">
                  <b>{{ 'landingPublicAchievements.tonnes' | translate}}</b>
                  <br>
                  <span [innerHtml]="'landingPublicImpacts.co2' | translate"></span>
                </div>
              </div>
            </div>
            <!-- <mat-divider *ngIf="(windowWidth < 992 || isVertical)" id="divider-top"></mat-divider> -->
            <div class="co2-breakdown" id="co2-breakdown-id" [ngClass]="{'hidden' : (hideCo2Breakdown)}">
              <div class="breakdown-header" [ngClass]="{'mt-4 mb-2': (windowWidth < 992 || forApi || isVertical)}">
                <div class="ofWhich">{{ 'landingPublicAchievements.ofWhich' | translate }}</div>
                <app-widget-tooltip [whitelabel]="whitelabel"></app-widget-tooltip>
              </div>
              <div class="breakdown-body flex-wrap">
                <div class="item" *ngIf="projectType != 'marine_restoration'" >
                  <div class="icon">
                    <img src="/assets/images/icons/science.svg" />
                  </div>
                  <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
                  <div class="impact co2">
                    <div class="value">
                      <span class="countup bold" *ngIf="countersPublicInterface?.scienceBacked > 0">{{countersPublicInterface?.scienceBackedStr}}</span>
                      <span class="countup bold" *ngIf="countersPublicInterface?.scienceBacked == 0">0</span>
                    </div>
                    <div class="small-body">
                      <!-- <b *ngIf="(windowWidth < 992 || isVertical)">t</b>
                      <b *ngIf="(windowWidth >= 992 && !isVertical)">{{ 'landingPublicAchievements.tonnes' | translate }}</b> -->
                      <b>{{ 'landingPublicAchievements.tonnes' | translate }}</b>
                      <br>
                      {{ 'landingPublicAchievements.scienceBacked' | translate}}
                    </div>
                  </div>
                </div>
                <div class="item ml-1" *ngIf="projectType != 'marine_restoration'" >
                  <div class="icon">
                    <img src="/assets/images/icons/certified.svg" />
                  </div>
                  <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
                  <div class="impact co2">
                    <div class="value">
                      <span class="countup bold" *ngIf="countersPublicInterface?.certified > 0">{{countersPublicInterface?.certifiedStr}}</span>
                      <span class="countup bold" *ngIf="countersPublicInterface?.certified == 0">0</span>
                    </div>
                    <div class="small-body">
                      <!-- <b *ngIf="(windowWidth < 992 || isVertical)">t</b>
                      <b *ngIf="(windowWidth >= 992 && !isVertical)">{{ 'landingPublicAchievements.tonnes' | translate }}</b> -->
                      <b>{{ 'landingPublicAchievements.tonnes' | translate }}</b>
                      <br>
                      {{ 'landingPublicAchievements.certified' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showCTA && (windowWidth >= 992 && !forApi && !isVertical)" class="actions" [ngClass]="((windowWidth < 992 || forApi || isVertical) && !showStats) ? 'mobile-actions-projects-spacing' : ''">
              <button mat-stroked-button color="green" (click)="toggleStatsView()">
                {{ 'landingPublicAchievements.checkOutProject' | translate }}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z"/>
                </svg>
              </button>
              <!-- <button mat-raised-button color="green" *ngIf="company && company.slug" (click)="onLearnMore()"> -->
              <button mat-raised-button color="green" *ngIf="company" (click)="onLearnMore()">
                {{ 'landingPublicAchievements.learnMore' | translate }}
              </button>
            </div>
          </div>
          <mat-divider id="divider-bottom" *ngIf="projectType != 'marine_restoration'"></mat-divider>
          <div class="content text-center justify-content-start align-items-end">
            <div class="item" *ngIf="!projectType || projectType == 'restoration'" [ngClass]="{'hidden' : (countersPublicInterface?.restoration?.unit == 0)}">
              <div class="icon">
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Project Icons">
                  <circle id="Ellipse" cx="28" cy="28" r="28" fill="#C3E8C1"/>
                  <path id="Vector 39" d="M28.5 16.5V24M28.5 48V35M28.5 24L34.5 20.5M28.5 24V29M28.5 29L23 26.5M28.5 29V35M28.5 35L39.3841 29.3403C40.377 28.824 41 27.7978 41 26.6786V16.3214C41 15.2022 40.377 14.176 39.3841 13.6597L29.8324 8.69285C28.9937 8.25675 27.9986 8.24142 27.1469 8.65149L16.6986 13.6822C15.6602 14.1821 15 15.2327 15 16.3852V26.6148C15 27.7673 15.6602 28.8179 16.6986 29.3178L28.5 35Z" stroke="#002822" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                </svg>                  
              </div>
              <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
              <div class="impact trees" >
                <div class="value">
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.restoration?.unit > 0">{{countersPublicInterface?.restoration?.unitStr}}</span>
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.restoration?.unit == 0">0</span>

                </div>
                <div class="small-body">
                  <b>{{ 'landingPublicImpacts.trees' | translate}}</b>
                  <br>
                  {{ 'landingPublicImpacts.planted' | translate}}
                </div>
              </div>
            </div>
            <div class="item" *ngIf="!projectType || projectType == 'preservation'">
              <div class="icon">
                <img src="/assets/images/icons/preservation.svg" />
              </div>
              <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
              <div class="impact acres">
                <div class="value">
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.preservation?.unit > 0">{{countersPublicInterface?.preservation?.unitStr}}</span>
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.preservation?.unit == 0">0</span>
                </div>
                <div class="small-body">
                  <b [innerHtml]="preservationUnit"></b>
                  <br>
                  {{ 'landingPublicImpacts.area' | translate}}
                </div>
              </div>
            </div>
            <div class="co2-breakdown" id="co2-breakdown-id" *ngIf="!projectType || projectType == 'renewable_energy'">
              <div class="breakdown-header" [ngClass]="{'mt-4 mb-2': (windowWidth < 992 || forApi || isVertical)}">
                <div class="ofWhich"></div>
                <app-widget-tooltip-disclaimer [whitelabel]="whitelabel"></app-widget-tooltip-disclaimer>
              </div>
              <div class="breakdown-body flex-wrap">
                <div class="item"  [ngClass]="{'hidden' : (countersPublicInterface?.renewable_energy?.unit == 0)}">
                  <div class="icon">
                    <img src="/assets/images/icons/renewable.svg" />
                  </div>
                  <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
                  <div class="impact kwh" [ngClass]="{'hidden' : (hideImpact)}">
                    <div class="small-body">
                      <div class="value">
                        <span class="title-2 countup bold" *ngIf="countersPublicInterface?.renewable_energy?.unit > 0">{{countersPublicInterface?.renewable_energy?.unitStr}}</span>
                        <span class="title-2 countup bold" *ngIf="countersPublicInterface?.renewable_energy?.unit == 0">0</span>
                      </div>
                    </div>
                    <div class="small-body">
                      <b>{{renewableUnit}}</b>
                      <br>
                      {{ 'landingPublicImpacts.renewable' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="item" *ngIf="!projectType || projectType == 'marine_restoration'" [ngClass]="{'hidden' : (countersPublicInterface?.marine_restoration?.unit == 0)}">
              <div class="icon">
                <img src="/assets/images/public/home/marine_restoration.svg" />
              </div>
              <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
              <div class="impact marine" >
                <div class="value">
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.marine_restoration?.unit > 0">{{countersPublicInterface?.marine_restoration?.unitStr}}</span>
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.marine_restoration?.unit == 0">0</span>
                </div>
                <div class="small-body">
                  <b>{{ 'landingPublicImpacts.corals' | translate}}</b>
                  <br>
                  {{ 'landingPublicImpacts.planted' | translate}}
                </div>
              </div>
            </div>
            <div class="item" *ngIf="!projectType || projectType == 'seedlings'">
              <div class="icon">
                <img src="/assets/images/icons/seedlings.svg"/>
              </div>
              <div class="lds-ellipsis" [ngClass]="{'hidden' : (hideEllipsis)}"><div></div><div></div><div></div><div></div></div>
              <div class="impact seedlings">
                <div class="value">
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.seedlings?.unit > 0">{{countersPublicInterface?.seedlings?.unitStr}}</span>
                  <span class="title-2 countup bold" *ngIf="countersPublicInterface?.seedlings?.unit == 0">0</span>
                </div>
                <div class="small-body">
                  <b>{{ 'landingPublicImpacts.seedlings' | translate}}</b>
                  <br>
                  {{ 'landingPublicImpacts.planted-w' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="((windowWidth < 992 || isVertical)) && !showStats" class="mobile-subtitle lato">
          {{ 'landingPublicAchievements.mobileSubTitle' | translate }}
        </div>
        <div class="projects" *ngIf="!showStats">
          <carousel
            class="projects-container d-flex align-items-center"
            [arrows]="arrows || isVertical || (projects?.length > 3)"
            [height]="200"
            [cellWidth]="200"
            [margin]="12">
            <div class="carousel-cell" *ngFor="let project of projects">
              <div class="card-container h-100 w-100" [ngStyle]="{ 'background-image': getProjectBackground(project), 'box-shadow': 'inset 0 0 0 1000px rgba(0,0,0,.2)' }">
                <div class="type" [ngStyle]="{'background-color': getProjectTypeColor(project)}">
                  {{ project.type.name }}
                </div>
                <div class="country d-flex align-items-center text-uppercase">
                  <svg-icon src="assets/images/icons/pin.svg" alt="Pin"></svg-icon>
                  {{ project.country.name || '-' }}
                </div>
                <div class="name">
                  {{ project.name }}
                </div>
                <button (click)="onLiveExperience(project)">
                  <svg-icon src="/assets/images/icons/dot.svg"></svg-icon>
                  <span>{{ 'landingPublicAchievements.goToLiveExperience' | translate }}</span>
                </button>
              </div>
            </div>
          </carousel>
        </div>
        <div class="actions" [ngClass]="((windowWidth < 992 || isVertical || forApi) && !showStats) ? 'mobile-actions-projects-spacing' : ''" *ngIf="!showStats">
          <button mat-stroked-button color="green" (click)="toggleStatsView()">
            <svg class="rotate" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z"/>
            </svg>
            {{ 'landingPublicAchievements.backToImpact' | translate }}
          </button>
          <button mat-raised-button color="green" *ngIf="company && company.slug && !aldar" (click)="onLearnMore()">
            {{ 'landingPublicAchievements.allProjects' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="showStats && showCTA && (windowWidth < 992 || isVertical || forApi)" class="actions" [ngClass]="((windowWidth < 992 || isVertical || forApi) && showStats) ? 'mobile-actions-projects-spacing' : ''">
      <button mat-stroked-button color="green" (click)="toggleStatsView()">
        {{ 'landingPublicAchievements.checkOutProject' | translate }}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z"/>
        </svg>
      </button>
      <button mat-raised-button color="green" *ngIf="company && company.slug" (click)="onLearnMore()">
        {{ 'landingPublicAchievements.learnMore' | translate }}
      </button>
    </div>
    <div class="logo" *ngIf="(windowWidth < 992 || isVertical || forApi)">
      <span class="px-3">Powered by</span>
      <svg-icon src="assets/images/svg/logoTitle.svg" [svgStyle]="{'width.px':46, 'height.px':40}"></svg-icon>
    </div>
  </div>
</div>
