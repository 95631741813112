import { Component, Renderer2,  OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NgxSpinnerService } from "ngx-spinner";

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'green-future-project';
  private unlisten: () => void;
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  constructor(
    public router: Router,
    public translate: TranslateService,
    private titleService: Title,
    private metaService: Meta,
    private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2
  ) {
    
    let languages = /en|it|jp/;
    let langTranslate = ['it', 'en', 'jp'];

    // let redirect = this.checkRedirect(window.location.href);
    this.setLang(languages,langTranslate);
    localStorage.setItem('clientType', '2');

    /* this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        redirect = this.checkRedirect(event.url);
        const gtmTag = {
          event: 'page_view',
          pageName: event.url
        };
        this.gtmService.pushTag(gtmTag);
        this.setMeta();
      }
    }); */
  }

  checkRedirect(url: string): boolean {
    return !(
      localStorage.getItem('clientType') != null ||
      localStorage.getItem('username') != null ||
      url.indexOf('/company/') >= 0 ||
      url.indexOf('/register') >= 0 ||
      url.indexOf('/resetPassword') >= 0 ||
      url.indexOf('utm_source') >= 0
    )
  }

  setLang(languages,langTranslate): void {
    const selectedLang = localStorage.getItem('lang');
    if (selectedLang) {
      this.translate.use(selectedLang.match(languages) ? selectedLang : 'en');
    }
    else {
      this.translate.addLangs(langTranslate);
      this.translate.setDefaultLang('en');
      const browserLang = this.translate.getBrowserLang();
      this.translate.use(browserLang && browserLang.match(languages) ? browserLang : 'en');
      localStorage.setItem('lang', this.translate.currentLang);
    }
  }

  setMeta(): void {
    const rt = this.getChild(this.activatedRoute);
    rt.data.subscribe((data) => {
      this.metaService.addTag({ property: 'og:url', content: this.router.url });
      if (data.page) {
        const title = data.page.title ? data.page.title :
          this.translate.instant('pages.' + data.page.slug + '.title');
        const description = data.page.description ? data.page.description :
          this.translate.instant('pages.' + data.page.slug + '.description');
        let fullTitle = title;
        fullTitle = title + ' | Green Future Project'
        this.titleService.setTitle(fullTitle);
        this.metaService.addTag({ name: 'description', content: description });
        if (data.page.keywords) this.metaService.addTag({ name: 'keywords', content: data.page.keywords.toString() });
        this.metaService.addTag({ property: 'og:title', content: fullTitle });
        this.metaService.addTag({ property: 'og:description', content: description });
      } else {
        this.metaService.removeTag("name='description'");
        this.metaService.removeTag("property='og:title'");
        this.metaService.removeTag("property='og:description'");
      }
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  ngOnInit() {
    // if (!this.isSafari){
      this.spinner.show();
      this.unlisten = this.renderer.listen('window', 'load', () => {
        this.spinner.hide();
      });
    // }
  }

  ngOnDestroy() {
    // if (!this.isSafari){
      if (this.unlisten) {
        this.unlisten();
      }
    // }
  }

}
