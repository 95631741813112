import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { PageTypes } from 'src/app/_models/components/page-types';
import { ProjectTypes } from 'src/app/_models/components/project-types';

@Injectable({
  providedIn: 'root'
})
export class ProjectTypeResolver  {
  constructor(private translate: TranslateService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    const id = route.params.slug;
    const value = this.translate.instant('breadcrumb.' + id);
    return value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectTypePageResolver  {
  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const id = route.params.slug;
    let page = PageTypes.PUBLIC.PROJECTS.ALL;
    switch (id) {
      case ProjectTypes.restoration:
        page = PageTypes.PUBLIC.PROJECTS.RESTORATION;
        break;
      case ProjectTypes.preservation:
        page = PageTypes.PUBLIC.PROJECTS.PRESERVATION;
        break;
      case ProjectTypes.renewable_energy:
        page = PageTypes.PUBLIC.PROJECTS.RENEWABLE;
        break;
      case ProjectTypes.marine_restoration:
        page = PageTypes.PUBLIC.PROJECTS.MARINE;
        break;
    }
    return page;
  }
}
