import { Component, OnInit, Input } from '@angular/core';
import {BaseComponent} from '../../../_base/base/base.component';
import {StorageName} from '../../../../_models/components/storage-name';
import {CountersPublicInterface} from '../../../../_models/api/public/counters-public-interface';
import {CountersPublicService} from '../../../../_services/public/counters.public.service';
import { ProjectsService } from '../../../../_services/projects/projects.service';
import {CompanyService} from '../../../../_services/companies/company.service';
import {Response} from '../../../../_models/api/response';
import { ProjectTypes } from 'src/app/_models/components/project-types';
import { CompanyInterface } from '../../../../_models/api/companies/company-interface';
import { UserProjectsService } from 'src/app/_services/projects/user-projects.service';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';
import { CompanyProjectsService } from 'src/app/_services/projects/company-projects.service';

interface ProjectInterface {
  name: string;
  type: {
    slug: string;
    name: string;
  };
  image: {
    url: string;
  };
  country: {
    name?: string;
  };
  slug: string;
  status: string;
  createdAt: Date;
  isActive?: boolean;
}

@Component({
  selector: 'app-achievements-data',
  templateUrl: './achievements-data.component.html',
  styleUrls: ['./achievements-data.component.scss']
})

export class AchievementsDataComponent extends BaseComponent implements OnInit {
  countersPublicInterface: CountersPublicInterface;
  @Input() forApi: boolean;
  @Input() company: CompanyInterface;
  @Input() projectType: string;
  @Input() projectTypeName: string = '';
  windowWidth = window.innerWidth;
  arrows = window.innerWidth < 992;
  @Input() showCTA: boolean = true;
  @Input() isVertical: boolean = false;
  @Input() projectTypeFilter: string;
  @Input() align: string;

  preservationUnit: string = 'ha';
  renewableUnit: string = 'kWh';
  co2Unit: string = 'kg';
  showStats: boolean = true;
  projects: ProjectInterface[] = [];
  isLargeScreen: boolean;
  hideCo2Breakdown: boolean = false;
  hideEllipsis: boolean = false;
  hideImpact: boolean = true;
  partnership = localStorage.getItem(StorageName.partnership);
  reteclima = this.partnership === PartnershipNames.reteclima;
  adnoc = this.partnership === PartnershipNames.adnoc;
  aldar = this.partnership === PartnershipNames.aldar;
  tgi = this.partnership === PartnershipNames.tgi;
  whitelabel = this.partnership === PartnershipNames.whitelabel;

  constructor(
    private countersPublicService: CountersPublicService,
    private companyService: CompanyService,
    private projectsService: ProjectsService,
    private userProjectsService: UserProjectsService,
    private companyProjectsService: CompanyProjectsService,
  ) {
    super();
  }

  ngOnInit(): void {
    if(this.company) {
      const userId = localStorage.getItem(StorageName.id);
      //this.companyProjectsService.getCompanyProjects(this.company.id).then((res: {data: ProjectInterface[]}) => {
      this.userProjectsService.getUserProjects(userId).then((res: {data: ProjectInterface[]}) => {
        //TO-FIX blocked at 3 projects as the shared widget
        this.projects = this.filterProjects(res.data,3,true);
      })
    } else {
      this.projectsService.getPublicProjects().then((res: {data: ProjectInterface[]}) => {
        //this.projects = this.filterProjects(res.data, 3);
        this.projects = this.filterProjects(res.data);
      });
    }
    this.arrows = this.projects.length > 3;
    this.getCounters().then();
    // TOFIX
    if (this.projectType) this.hideCo2Breakdown = true;
    if (this.projectType && !this.projectTypeName) {
      this.projectTypeName = this.projectType.replace('_', ' ') + ' ';
    }
  }

  filterProjects(projects: ProjectInterface[], slice?: number, hideStatusActive?: boolean): ProjectInterface[] {
    let filtered = projects;
    if (hideStatusActive)
      filtered = projects.filter(project => project.isActive).sort((a, b) => (new Date(b.createdAt)).getUTCMilliseconds() - (new Date(a.createdAt)).getUTCMilliseconds());
    else
      filtered = projects.filter(project => project.status === 'active' || project.isActive).sort((a, b) => (new Date(b.createdAt)).getUTCMilliseconds() - (new Date(a.createdAt)).getUTCMilliseconds());

    if (this.projectTypeFilter) {
      filtered = filtered.filter(project => project.type.slug === this.projectTypeFilter);
    }

    if(slice) {
      return filtered.slice(0, slice);
    } else {
      return filtered;
    }
  }

  toggleStatsView() {
    this.showStats = !this.showStats;
    if(this.showStats) {
      // this.getCounters().then();
      if (this.projectType && !this.projectTypeName) {
        this.projectTypeName = this.projectType.replace('_', ' ') + ' ';
      }
    }
  }

  onResize = () => {
    this.windowWidth = window.innerWidth;
    this.arrows = window.innerWidth < 992;
  }

  getCounters = async () => {
    try {
      let params = {};
      // if (this.projectType) {
      //   params['projectType'] = this.projectType;
      // }
      let response: Response<CountersPublicInterface>;
      if (this.company) {
        response = await this.companyService.getCompanyCounters(this.company.id);
      } else {
        response = await this.countersPublicService.getPublicCounters(params);
      }
      if (response?.success) {
        this.countersPublicInterface = response.data;

        const trees = this.countersPublicInterface.restoration.unit;
        const restorationCO2 = Number(this.countersPublicInterface.restoration.certified) + Number(this.countersPublicInterface.restoration.notCertified);
        const preservationCO2 = Number(this.countersPublicInterface.preservation.certified) + Number(this.countersPublicInterface.preservation.notCertified);
        const renewableCO2 = Number(this.countersPublicInterface.renewable_energy.certified) + Number(this.countersPublicInterface.renewable_energy.notCertified);
        const hectares = this.countersPublicInterface.preservation.unit;
        const acres = hectares * 0.404686;
        const metres = Math.round(acres * 10000 * 100) / 100;
        const kwh = this.countersPublicInterface.renewable_energy.unit;
        const corals = Math.ceil(this.countersPublicInterface.marine_restoration.unit);
        const seedlings = (this.countersPublicInterface.seedlings) ? Math.ceil(this.countersPublicInterface.seedlings.unit) : 0;
        let co2 = 0;

        let scienceBackedCO2 = Number(this.countersPublicInterface.restoration.notCertified) + Number(this.countersPublicInterface.preservation.notCertified) + Number(this.countersPublicInterface.renewable_energy.notCertified);
        let certified = Number(this.countersPublicInterface.restoration.certified) + Number(this.countersPublicInterface.preservation.certified) + Number(this.countersPublicInterface.renewable_energy.certified);
        if (this.countersPublicInterface.tonnes) certified += Number(this.countersPublicInterface.tonnes.certified);

        switch (this.projectType) {
          case ProjectTypes.restoration:
            co2 += restorationCO2;
            break;
          case ProjectTypes.preservation:
            co2 += preservationCO2;
            break;
          case ProjectTypes.renewable_energy:
            co2 += renewableCO2;
            break;
          case ProjectTypes.marine_restoration:
            break;
          default:
            co2 += restorationCO2 + preservationCO2 + renewableCO2;
            if (this.countersPublicInterface.tonnes) {
              co2 += this.countersPublicInterface.tonnes.certified;
            }
            break;
        }

        const roundedTrees = trees | 0;
        this.countersPublicInterface.restoration.unit = roundedTrees;
        this.countersPublicInterface.restoration.unitStr = this.humanize(roundedTrees);
        const roundedAcres = acres | 0;
        //this.countersPublicInterface.preservation.unit = roundedAcres;
        //this.countersPublicInterface.preservation.unitStr = this.humanize(roundedAcres);
        let roundedHectares = 0;
        if (hectares >= 1) {
          roundedHectares = ((hectares * 100) | 0) / 100;
        } else {
          roundedHectares = ((hectares * 10000 * 100) | 0) / 100;
          this.preservationUnit = 'm<sup>2</sup>';
        }
        this.countersPublicInterface.preservation.unit = roundedHectares;
        this.countersPublicInterface.preservation.unitStr = this.humanize(roundedHectares);
        let roundedKWh = kwh;
        if (kwh >= 1000) {
          // roundedKWh = kwh / 1000 | 0;
          roundedKWh = kwh / 1000;
          this.renewableUnit = 'MWh';
        }
        this.countersPublicInterface.renewable_energy.unit = roundedKWh;
        this.countersPublicInterface.renewable_energy.unitStr = this.humanize(roundedKWh);
        this.countersPublicInterface.marine_restoration.unitStr = this.humanize(corals);
        if (seedlings != 0) this.countersPublicInterface.seedlings.unitStr = this.humanize(seedlings);
        //this.countersPublicInterface = this.countersPublicInterface;
        // let roundedCo2 = Math.round(co2);
        // let roundedCertified = Math.round(certified);
        // let roundedScienceBacked = Math.round(scienceBackedCO2);
        let roundedCo2 = co2;
        let roundedCertified = certified;
        let roundedScienceBacked = scienceBackedCO2;
        // let certifiedUnit = 'kg';
        // let scienceBackedUnit = 'kg';
        // if (certified > 1000) {
        //   roundedCertified = (roundedCertified / 1000);
        //   certifiedUnit = 't';
        // }

        // if (scienceBackedCO2 > 1000) {
        //   roundedScienceBacked = (roundedScienceBacked / 1000);
        //   scienceBackedUnit = 't';
        // }
        // if (co2 > 1000) {
        //   roundedCo2 = (co2 / 1000);
        //   this.co2Unit = 't';
        // }
        roundedCertified = (roundedCertified / 1000);
        let certifiedUnit = 't';
        roundedScienceBacked = (roundedScienceBacked / 1000);
        let scienceBackedUnit = 't';
        roundedCo2 = (co2 / 1000);
        this.co2Unit = 't';
        this.countersPublicInterface.co2 = roundedCo2;
        this.countersPublicInterface.co2Str = this.humanize(roundedCo2);
        this.countersPublicInterface.certified = roundedCertified;
        this.countersPublicInterface.certifiedStr = this.humanize(roundedCertified);
        this.countersPublicInterface.scienceBacked = roundedScienceBacked;
        this.countersPublicInterface.scienceBackedStr = this.humanize(roundedScienceBacked);

        if (!(this.countersPublicInterface.co2>0)) this.hideCo2Breakdown = true;
        this.hideEllipsis = true;
        this.hideImpact = false;
        // Hard fix as temporary error resolution
        //this.countersPublicInterface.restoration.unit = 274354;
        //this.countersPublicInterface.restoration.unitStr = '274,354';
        //this.countersPublicInterface.preservation.unit = 205;
        //this.countersPublicInterface.preservation.unitStr = '205';
        //this.countersPublicInterface.renewable_energy.unit = 1850;
        //this.countersPublicInterface.renewable_energy.unitStr = '1,850';
        //this.countersPublicInterface = this.countersPublicInterface;
        //this.countersPublicInterface.co2 = 6604;
        //this.countersPublicInterface.co2Str = '6,604';

      } else {
        this.showErrorResponse(response);
      }
    } catch (e) {
      this.showErrorResponse(e);
    }
  }

  onLearnMore() {
    if (this.company.slug)
      window.open(`/company/${this.company.slug}`, '_blank');
    else
      window.open(`/`, '_blank');
  }

  onLiveExperience(project: ProjectInterface) {
    this.router.navigate(['admin/projects', project.slug, 'live']);
  }

  getProjectTypeColor(project: ProjectInterface) {
    const COLOR_MAP = {
      'preservation': '#B5E7EA',
      'restoration': '#C3E8C1',
      'renewable energy': '#F4B68C',
      'marine restoration': '#9FBEDA'
    }

    const key = project.type.name.toLowerCase();
    const color = COLOR_MAP[key] || 'white';

    return color;
  }

  getProjectBackground(project: ProjectInterface){
    const url = project.image.url;
    const res = 'url("' + url + '")';
    return res;
  }

  /* getFixedCounters(response): void {

  } */

}
