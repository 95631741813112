<div class="menu-item" [ngClass]="{'whitelabel': whitelabel}">
  <ng-container *ngIf="item.visible != false">
    <ng-container *ngIf="item.displayName == 'logout', else displayOther">
      <ng-container *ngIf="username">
        <mat-list-item [disableRipple]="rippleState"
                       [routerLinkActive]="['active']">
          <a class="nav-link" (click)="onItemSelected(item)"
             [ngClass]="{'enabled':item.enable==false, 'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}">
            <div class="d-flex align-items-center" *ngIf="item.displayName == 'logout' && (!tgi && !reteclima) " (click)="logout('/')">
              <fa-icon class="me-3" *ngIf="item.iconName != '' && item.iconName != null" [icon]="['far', item.iconName]"></fa-icon>
              <span class="chivo sm" [ngClass]="{'sidebar-item':whitelabel}">{{'sidebar.' + item.displayName | translate}}</span>
            </div>
            <div class="d-flex align-items-center" *ngIf="item.displayName == 'logout' && tgi" (click)="logout('login','tgi')">
              <fa-icon class="me-3" *ngIf="item.iconName != '' && item.iconName != null" [icon]="['far', item.iconName]"></fa-icon>
              <span class="chivo sm">{{'sidebar.' + item.displayName | translate}}</span>
            </div>
            <div class="d-flex align-items-center" *ngIf="item.displayName == 'logout' && reteclima" (click)="logout('login','reteclima')">
              <fa-icon class="me-3" *ngIf="item.iconName != '' && item.iconName != null" [icon]="['far', item.iconName]"></fa-icon>
              <span class="chivo sm">{{'sidebar.' + item.displayName | translate}}</span>
            </div>
          </a>
        </mat-list-item>
      </ng-container>
    </ng-container>
    <ng-template #displayOther>
      <mat-list-item [disableRipple]="rippleState"
                     [routerLinkActive]="['active']"
                     [ngClass]="{'active': item.route ? router.isActive(item.route, true): false}">
        <a class="nav-link"
          (click)="onItemSelected(item)"
          [ngClass]="{'enabled':item.enable==false, 'expanded': item.expanded}"
          *ngIf="!item.new">
          <div class="d-flex align-items-center">
            <fa-icon *ngIf="item.iconName && item.iconName != ''" [icon]="['far', item.iconName]" class="me-3"></fa-icon>
            <img [src]="'/assets/images/public/menu/'+item.image+'.svg'" class="me-3" *ngIf="item.image">
            <span class="chivo sm sidebar-item">
              <span innerHTML="{{'sidebar.' + item.displayName | translate}}">  </span>
            </span>
          </div>
          <mat-icon *ngIf="item.children && item.children.length">
            expand_more
          </mat-icon>
        </a>
        <a class="nav-link"
          (click)="onItemSelected(item)"
          matTooltip="{{'sidebar.' + item.displayName | translate}}"
          matTooltipPosition="after"
          [ngClass]="{'enabled':item.enable==false, 'expanded': item.expanded}"
          *ngIf="item.new">
          <div class="d-flex align-items-center">
            <fa-icon *ngIf="item.iconName && item.iconName != ''" [icon]="['far', item.iconName]" class="me-3"></fa-icon>
            <img [src]="'/assets/images/public/menu/'+item.image+'.svg'" class="me-3" *ngIf="item.image">
            <span class="chivo sm sidebar-item">
              <span innerHTML="{{'sidebar.' + item.displayName | translate | slice:0:9}}...">
                
              </span>
            </span>
            <div class="ms-2 bg-warning bg-opacity-75 rounded-pill py-1 px-2 title-4 text-dark ml-2 position-relative">
              {{'sidebar.new' | translate}}
            </div>
          </div>
          <mat-icon *ngIf="item.children && item.children.length">
            expand_more
          </mat-icon>
        </a>
        <div class="menu-wrong d-none" id="menu-wrong-{{item.displayName}}">
          <div class="d-flex">
            <p class="small-body m-0 border-0 lato" *ngIf="item.plugin">{{'sidebar.pluginNotActivated' | translate}}</p>
            <p class="small-body m-0 border-0 lato" *ngIf="!item.plugin">{{'sidebar.optionNotActivated' | translate}}</p>
            <div>
              <mat-icon (click)="close($event, item.displayName)" class="close h-0 text-dark">clear</mat-icon>
            </div>
          </div>
          <ng-container *ngIf="item.displayName == 'whitelabel'; else sustainabilityButton">
            <a [routerLink]="lang === 'it'? routingTypes.WHITELABEL_IT : lang === 'jp'? routingTypes.API : routingTypes.WHITELABEL" mat-raised-button class="w-100 mt-4 shadow-none" color="yellow" target="_blank">
              {{'sidebar.learnMore' | translate}}
            </a>
          </ng-container>
          <ng-template #sustainabilityButton>
            <a [routerLink]="lang === 'it' ? routingTypes.ENERGY_IT : routingTypes.ENERGY" mat-raised-button class="w-100 mt-4 shadow-none" color="yellow"  target="_blank" *ngIf="!item.plugin">
              {{'sidebar.learnMore' | translate}}
            </a>
            <a [routerLink]="lang === 'it'? routingTypes.SUSTAINABILITY_IT : lang === 'jp'? routingTypes.API : routingTypes.SUSTAINABILITY" mat-raised-button class="w-100 mt-4 shadow-none" color="yellow" target="_blank" *ngIf="item.plugin">
              {{'sidebar.learnMore' | translate}}
            </a>
          </ng-template>
        </div>
      </mat-list-item>
    </ng-template>
  </ng-container>
  <div *ngIf="item.expanded" [ngStyle]="{'padding': '0 1.5rem'}">
    <app-menu-item (toggleItemSidenav)="toggleItemSidenav.emit()" *ngFor="let child of item.children" [item]="child"
                   [depth]="depth+1" [route]="item.route">
    </app-menu-item>
  </div>
</div>
