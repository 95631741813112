export enum StorageName {
  token = 'token',
  pilioLastAccess = 'pilioLastAccess',
  name = 'name',
  id = 'id',
  language = 'lang',
  companyId = 'companyId',
  companyName = 'companyName',
  pilio = 'pilio',
  userCompanyRole = 'userCompanyRole',
  subscriptionId = 'subscriptionId',
  subscription = 'subscription',
  cart = 'cart',
  username = 'username',
  userData = 'userData',
  businessEmployees = 'businessEmployees',
  businessPioneer = 'businessPioneer',
  shopName = 'shopName',
  userType = 'userType',
  clientType = 'clientType',
  nativaTransactionData='nativaTransactionData',
  country = 'country',
  partnership = 'partnership',
  pluginCheck = 'pluginCheck',
  whitelabelCheck = 'whitelabelCheck',
  whitelabelWelcome = 'whitelabelWelcome',
  whitelabelSaved = 'whitelabelSaved',
  rememberEmail = 'GFPRememberEmail',
  profile = 'profile',
  justLogged = 'justLogged'
}

